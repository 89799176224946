// Stylesheet by A4 Agentur AG

/* ======================= resets ======================== */

// set base font size to 16px
html { 
	font-size: 16px;
	overflow-y: scroll; 
	-webkit-text-size-adjust: 16px; 
	-ms-text-size-adjust: 16px; 
}

// Template Remove A11Y Outlines
* { outline:none; }



/* ======================= /resets ======================== */


/* ======================= anchor ======================== */

a.anchor {
    display: block;
    position: relative;
    top: -$topbar-height;
    visibility: hidden;
}

@include breakpoint(small only) { 
	a.anchor {
	    top: -$titlebar-height;
	}
}


a.fa {
	font-family: inherit;
}

a.fa:before {
	font-family: 'FontAwesome';
	margin: 0 5px 0 0;
}

/* ======================= /anchor ======================== */

/* ======================= grid ======================== */

.wrapper {
	padding: 1rem;
	//border: 1px solid red;
}

@include breakpoint(medium) { 
	.wrapper {
		padding: 2rem;
	}
}

@media screen and (min-width: rem-calc(1300)) {
	.full {
		padding: 0 50px 0 50px;
	}
	.left {
		padding: 0 0 0 50px;
	}
	.right {
		padding: 0 50px 0 0;
	}
}

@media screen and (min-width: rem-calc(1400)) {
	.full {
		padding: 0 100px 0 100px;
	}
	.left {
		padding: 0 0 0 100px;
	}
	.right {
		padding: 0 100px 0 0;
	}
}

@media screen and (min-width: rem-calc(1500)) {
	.full {
		padding: 0 150px 0 150px;
	}
	.left {
		padding: 0 0 0 150px;
	}
	.right {
		padding: 0 150px 0 0;
	}
}

@media screen and (min-width: rem-calc(1600)) {
	.full {
		padding: 0 200px 0 200px;
	}
	.left {
		padding: 0 0 0 200px;
	}
	.right {
		padding: 0 200px 0 0;
	}
}

@media screen and (min-width: rem-calc(1700)) {
	.full {
		padding: 0 250px 0 250px;
	}
	.left {
		padding: 0 0 0 250px;
	}
	.right {
		padding: 0 250px 0 0;
	}
}

@media screen and (min-width: rem-calc(1800)) {
	.full {
		padding: 0 300px 0 300px;
	}
	.left {
		padding: 0 0 0 300px;
	}
	.right {
		padding: 0 300px 0 0;
	}
}

@media screen and (min-width: rem-calc(1900)) {
	.full {
		padding: 0 350px 0 350px;
	}
	.left {
		padding: 0 0 0 350px;
	}
	.right {
		padding: 0 350px 0 0;
	}
}

@media screen and (min-width: rem-calc(2000)) {
	.full {
		padding: 0 400px 0 400px;
	}
	.left {
		padding: 0 0 0 400px;
	}
	.right {
		padding: 0 400px 0 0;
	}
}

@media screen and (min-width: rem-calc(2100)) {
	.full {
		padding: 0 450px 0 450px;
	}
	.left {
		padding: 0 0 0 450px;
	}
	.right {
		padding: 0 450px 0 0;
	}
}

@media screen and (min-width: rem-calc(2200)) {
	.full {
		padding: 0 500px 0 500px;
	}
	.left {
		padding: 0 0 0 500px;
	}
	.right {
		padding: 0 500px 0 0;
	}
}

@media screen and (min-width: rem-calc(2300)) {
	.full {
		padding: 0 550px 0 550px;
	}
	.left {
		padding: 0 0 0 550px;
	}
	.right {
		padding: 0 550px 0 0;
	}
}

@media screen and (min-width: rem-calc(2400)) {
	.full {
		padding: 0 600px 0 600px;
	}
	.left {
		padding: 0 0 0 600px;
	}
	.right {
		padding: 0 600px 0 0;
	}
}

@media screen and (min-width: rem-calc(2500)) {
	.full {
		padding: 0 650px 0 650px;
	}
	.left {
		padding: 0 0 0 650px;
	}
	.right {
		padding: 0 650px 0 0;
	}
}

@media screen and (min-width: rem-calc(2600)) {
	.full {
		padding: 0 700px 0 700px;
	}
	.left {
		padding: 0 0 0 700px;
	}
	.right {
		padding: 0 700px 0 0;
	}
}

@media screen and (min-width: rem-calc(2700)) {
	.full {
		padding: 0 750px 0 750px;
	}
	.left {
		padding: 0 0 0 750px;
	}
	.right {
		padding: 0 750px 0 0;
	}
}

@media screen and (min-width: rem-calc(2800)) {
	.full {
		padding: 0 800px 0 800px;
	}
	.left {
		padding: 0 0 0 800px;
	}
	.right {
		padding: 0 800px 0 0;
	}
}

/* ======================= /grid ======================== */

/* ======================= backgrounds ======================== */

$background-blue: $primary-color;
$background-black: $secondary-color;
$background-white: $white;
$background-lightblue: #d3e6f1;
$background-gray1: #aeb7c1;
$background-gray2: #2c363f;
$background-gray3: #5e6c79;
$background-gray4: #4a5a69;

.background-white {
	background: $background-white;
}

.background-lightblue {
	background: $background-lightblue;
}

.background-black {
	background: $background-black;
	color: $white;
}

.background-gray1 {
	background: $background-gray1;
	color: $body-font-color;
}

.background-gray2 {
	background: $background-gray2;
	color: $white;
}

.background-gray3 {
	background: $background-gray3;
	color: $white;
}

.background-gray4 {
	background: $background-gray4;
	color: $white;
}

@include breakpoint(small only) { 
	.background-20-20 .left {
		background: #b3ddc5;
	}
	.background-20-20 .right {
		background: #333333;
	}
	.background-19-21-blue-black .left {
		background: $background-blue;
		color: $white;
	}
	.background-19-21-blue-black .right {
		background: $background-black;
		color: $white;
	}
	.background-21-19-black-blue .left {
		background: $background-black;
	}
	.background-21-19-black-blue .right {
		background: $background-blue;
	}
	.background-21-19-blue-black .left {
		background: $background-blue;
		color: $white;
	}
	.background-21-19-blue-black .right {
		background: $background-black;
		color: $white;
	}
	.background-24-16-black-blue .left {
		background: $background-black;
	}
	.background-24-16-black-blue .right {
		background: $background-blue;
	}
	.background-24-16-white-black .left {
		background: $background-white;
	}
	.background-24-16-white-black .right {
		background: $background-black;
	}
	.background-24-16-white-lightblue .left {
		background: $background-white;
	}
	.background-24-16-white-lightblue .right {
		background: $background-lightblue;
	}
}

@include breakpoint(medium) { 
	.background-20-20 {
	  background: #b3ddc5; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, #b3ddc5 50%,#333333 50%,#333333 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, #b3ddc5 50%,#333333 50%,#333333 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, #b3ddc5 50%,#333333 50%,#333333 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, #b3ddc5 50%,#333333 50%,#333333 100%); /* Standard syntax */
	}    
	.background-19-21-blue-black {
	  background: $background-blue; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-blue 47.6%,$background-black 47.6%,$background-black 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-blue 47.6%,$background-black 47.6%,$background-black 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-blue 47.6%,$background-black 47.6%,$background-black 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-blue 47.6%,$background-black 47.6%,$background-black 100%); /* Standard syntax */
	  color: $white;
	}    
	.background-21-19-black-blue {
	  background: $background-black; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-black 52.6%,$background-blue 52.6%,$background-blue 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-black 52.6%,$background-blue 52.6%,$background-blue 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-black 52.6%,$background-blue 52.6%,$background-blue 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-black 52.6%,$background-blue 52.6%,$background-blue 100%); /* Standard syntax */
	}    

	.background-21-19-blue-black {
	  background: $background-blue; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-blue 52.4%,$background-black 52.4%,$background-black 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-blue 52.4%,$background-black 52.4%,$background-black 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-blue 52.4%,$background-black 52.4%,$background-black 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-blue 52.4%,$background-black 52.4%,$background-black 100%); /* Standard syntax */
	  color: $white;
	}    

	.background-24-16-black-blue {
	  background: $background-black; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-black 60%,$background-blue 60%,$background-blue 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-black 60%,$background-blue 60%,$background-blue 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-black 60%,$background-blue 60%,$background-blue 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-black 60%,$background-blue 60%,$background-blue 100%); /* Standard syntax */
	}

	.background-24-16-white-black {
	  background: $background-white; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-white 60.1%,$background-black 60.1%,$background-black 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-white 60.1%,$background-black 60.1%,$background-black 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-white 60.1%,$background-black 60.1%,$background-black 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-white 60.1%,$background-black 60.1%,$background-black 100%); /* Standard syntax */
	}
	
	.background-24-16-white-lightblue {
	  background: $background-white; /* For browsers that do not support gradients */
	  background: -webkit-linear-gradient(left, $background-white 60.1%,$background-lightblue 60.1%,$background-lightblue 100%); /* For Safari 5.1 to 6.0 */
	  background: -o-linear-gradient(right, $background-white 60.1%,$background-lightblue 60.1%,$background-lightblue 100%); /* For Opera 11.1 to 12.0 */
	  background: -moz-linear-gradient(right, $background-white 60.1%,$background-lightblue 60.1%,$background-lightblue 100%); /* For Firefox 3.6 to 15 */
	  background: linear-gradient(to right, $background-white 60.1%,$background-lightblue 60.1%,$background-lightblue 100%); /* Standard syntax */
	}

}

/* ======================= /backgrounds ======================== */

/* ======================= images ======================== */

.img-full img {
	width: 100%;
}

/* ======================= /images ======================== */

/* ======================= textcolors ======================== */

.textwhite {
	color: $white;
}

.textwhite a {
	color: $white;
}

/* ======================= /textcolors ======================== */

/* ======================= text ======================== */

.wrapper .textlarge, .wrapper.textlarge {
	font-size: 130%;
}

.wrapper h2 { 
	font-weight: 300; 
	margin: 0 0 1.3rem 0;
	line-height: 100%;
}

.wrapper .textpaddingright {
	padding: 0 1rem 0 0;
}

@include breakpoint(large down) {
// Only affects medium screens and smaller
	.wrapper p, .wrapper li {
		font-size: 95%;
	}
	.wrapper .textlarge, .wrapper.textlarge {
		font-size: 105%;
	}
	.wrapper h2 {
		font-size: 150%;
	}
}

@include breakpoint(medium down) {
// Only affects medium screens and smaller
	.wrapper p, .wrapper li {
		font-size: 90%;
	}
	.wrapper .textlarge, .wrapper.textlarge {
		font-size: 100%;
	}
	.wrapper h2 {
		font-size: 140%;
	}
}

/* ======================= /text ======================== */

/* ======================= sections ======================== */

.wrapper.sectiontitle p {
	margin: 20px 0 0 0;
}

.wrapper.sectiontitle h2 {
	margin: 0;
	line-height: 110%;
}

.wrapper.sectiontitle span {
	font-weight: 700;
}

.wrapper.sectionbody {
	padding-bottom: 50px;
}

@include breakpoint(small only) { 
// Only affects small screens
	.left .wrapper.sectionbody {
		padding-bottom: 0;
	}
	.right .wrapper.sectionbody {
		padding-top: 0;
	}
	.left .wrapper.sectionbody.hasbackground {
		padding-bottom: 1rem;
	}
	.right .wrapper.sectionbody.hasbackground {
		padding-top: 1rem;
	}
}


.wrapper.sectionbody h3 {
	margin: 0 0 30px 0;
	line-height: 110%;
}

.wrapper.sectionbody p.no-margin {
	margin: 0;
}

.wrapper.sectionbody.no-margin p {
	margin: 0;
}

.wrapper.sectionbody h3.no-margin {
	margin: 0 0 5px 0;
}

.wrapper.sectionbody h4 {
	margin: 0;
}

.wrapper.sectionbody span {
	font-weight: 500;
}

.wrapper.sectionbody section {
	padding: 40px 0 30px 0;
	border-bottom: 1px solid $medium-gray
}
.wrapper.sectionbody section:last-child {
	border-bottom: none;
}

//Partner, Referate
.wrapper .paddingleft {
    float: left;
    width: 25px;
}

/* ======================= /sections ======================== */

/* ======================= lists ======================== */

.wrapper .list-title {
	margin: 0;
	font-weight: 600;
}

.wrapper.list-title p {
	margin: 0;
	font-weight: 600;
}

.wrapper.list-title p.divider {
    margin-bottom: 1rem;
	font-weight: 400;
}

.wrapper ul { 
	list-style-type: circle; 
}

.wrapper.no-margin ul{
	margin-bottom: 0;
}

.wrapper.doubleslash ul { 
	list-style-type: none; 
	margin: 0 0 0 20px;
}

.wrapper.doubleslash ul li { 
	text-indent: -20px;
}

.wrapper.doubleslash ul li:before { 
	content: "//"; 
	text-indent: -20px;
	padding-right: 5px;
}

.wrapper.doubleslash.large ul { 
	list-style-type: none; 
	margin: 5px 0 20px 40px;
}

.wrapper.doubleslash.large ul li {
	font-size: rem-calc(31);
	line-height: 110%;
	text-indent: -40px;
}

.wrapper.doubleslash.large ul li:before { 
	content: "//"; 
	text-indent: -40px;
	padding-right: 10px;
}

@include breakpoint(small only) { 
// Only affects small screens
	.wrapper.doubleslash.large ul li {
		font-size: rem-calc(19)
	}
}


/* ======================= /lists ======================== */

/* ======================= icons ======================== */

.wrapper .icon-enveloppe {
	font-size: 1.5rem;
	line-height: 3rem
}

.wrapper .icon-plussign {
	font-size: 2rem;
	line-height: 3rem
}

/* ======================= /icons ======================== */

/* ======================= links ======================== */

.wrapper a { 
	color: $body-font-color; 
}

.wrapper.linkunderline a { 
    display:inline-block;
    border-bottom: 1px solid $body-font-color;
    line-height: 100%;
    padding-bottom:2px;
}

.wrapper .textwhite a, .wrapper.textwhite a { 
	color: $white; 
}


/* ======================= /links ======================== */

/* ======================= front schlüsselkompetenzen ======================== */

.cap-bot { 
	display: block; 
	position: relative; 
	float: left; 
	overflow: hidden; 
	margin: 0 0 20px 0;
}

.cap-bot figcaption { 
	position: absolute; 
	background: $primary-color; 
	color: white; 
	padding: 10px 20px; 
	left: 0; 
	right: 0;
	width: 100%;
	bottom: -100%;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
}

.cap-bot:hover figcaption {
	bottom: 0;
}

.cap-bot span {
	position: absolute; 
	color: white; 
	padding: 10px 20px 6px 20px; 
	width: 100%;
	bottom: 0;
	-webkit-transition: all 0.6s ease;
	-moz-transition: all 0.6s ease;
	-o-transition: all 0.6s ease;
}

.cap-bot h5 {
	font-weight: 500;	
}	

.cap-bot span.caption-1 {
	background: $background-gray1;
	color: $body-font-color; 
}

.cap-bot span.caption-2 {
	background: $background-gray2;
}

.cap-bot span.caption-3 {
	background: $background-gray3;
}

.cap-bot span.caption-4 {
	background: $background-gray4;
}

.cap-bot span.caption-5 {
	background: $background-gray3;
}

.cap-bot span.caption-6 {
	background: $background-gray2;
}

/* ======================= /front schlüsselkompetenzen ======================== */

/* ======================= team progress ======================== */

.progresscontainer {
	position: relative;
	float: left;
	//background: transparent;
	//margin: 0 5px;
	padding: 5px;
}

.progress {
    height: 140px;
    width: 140px;
}

.progress > svg {
    height: 100%;
    display: block;
}

.left .progress > svg {
    background: $background-blue;
}

.right .progress > svg {
    background: $background-black;
}

.progress p {
    font-size: 30px;
}

.progress p:after {
    content: '%';
	margin: 0 0 0 2px;
}

.progresscontainer .legend {
    width: 140px;
	position: absolute;
	text-align: center;
	bottom: 65px;
    font-size: 12px;
    font-weight: 600;
    color: $white;
    z-index: 1;
}

@include breakpoint(xlarge down) {
// Only affects xlarge screens and smaller
	.progress {
	    height: 120px;
	    width: 120px;
	}
	.progresscontainer .legend {
	    width: 120px;
	    bottom: 55px;
	}

}

/* ======================= /team progress ======================== */

/* ======================= form ======================== */

$form-font-size: 15px;


.wrapper form {
	margin: 40px 0 0 0;
}

.wrapper form .selectperson {
	padding: 10px 0 8px 0;
	margin: 0 0 40px 0;
	border-top: 1px solid $background-lightblue;
	border-bottom: 1px solid $background-lightblue;
}

.wrapper form .selectgender {
	padding: 8px 0 8px 0;
}

//error
.wrapper form .selectgender .radios.error {
    border: 1px solid red;
    padding: 5px;
	margin: 0 0 10px 0;
}

.wrapper form label {
    font-size: $form-font-size;
	text-align: left;
	color: $body-font-color;
	font-weight: 600;
}

@include breakpoint(small only) { 
// Only affects small screens
	.wrapper form {
		margin: 20px 0 0 0;
	}
	
	.wrapper form .selectperson {
		margin: 0 0 20px 0;
	}
	.wrapper form .fields label {
		display: none;
	}
}

.wrapper form input[type="text"], .wrapper form textarea {
    border: 1px solid $background-lightblue;
    background: $background-lightblue;
    box-shadow: none;
}

//error
.wrapper form input[type="text"].error, .wrapper form textarea.error {
    border: 1px solid red;
}

.wrapper form textarea {
	height: 200px;
	width: 100%;
}

.wrapper form .captchaimage {
	height: 39px;
	width: 100%;
    background: $background-lightblue;
}

.wrapper form .button {
    background: $background-gray4;
    color: $white;
    font-size: $form-font-size;
	font-weight: 600;
	padding: 10px 20px;
}

//placeholder

.wrapper form input[type="text"]::-webkit-input-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
}

.wrapper form input[type="text"]::-moz-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
    opacity: 1
}

.wrapper form input[type="text"]:-ms-input-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
}

.wrapper form textarea::-webkit-input-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
}

.wrapper form textarea::-moz-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
    opacity: 1
}

.wrapper form textarea:-ms-input-placeholder {
    font-size: $form-font-size;
    color: $body-font-color;
	font-weight: 600;
}

@include breakpoint(medium) { 
// Only affects medium screens and larger

	.wrapper form input[type="text"]::-webkit-input-placeholder {
    	color: $background-lightblue;
	}
	
	.wrapper form input[type="text"]::-moz-placeholder {
    	color: $background-lightblue;
	}
	
	.wrapper form input[type="text"]:-ms-input-placeholder {
    	color: $background-lightblue;
	}
	
	.wrapper form textarea::-webkit-input-placeholder {
    	color: $background-lightblue;
	}
	
	.wrapper form textarea::-moz-placeholder {
    	color: $background-lightblue;
	}
	
	.wrapper form textarea:-ms-input-placeholder {
    	color: $background-lightblue;
	}

}

//radios

.wrapper form .radios label {
    background-color: $white;
    display: inline-block;
    cursor: pointer;
    position: relative;
    padding-left: 18px
}

.wrapper form .radios input[type=radio] {
    display: none
}

.wrapper form .radios label:before {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 16px;
    margin: 0 0 0 -7px;
    position: absolute;
    left: 0;
    bottom: 6px;
    background-color: $background-lightblue;
}

.wrapper form .radios input[type=radio]:checked + label:before {
    background-color: $body-font-color
}


/* ======================= /form ======================== */

/* ======================= footer ======================== */

.footer {
	background: $primary-color;
	color: $white; 
	text-align: center;
	font-weight: 500;
	position: relative;
}

.footer:before {
	top: -27px;
	left: 50%;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	margin-left: -40px;
	border-style: solid;
	border-width: 0 40px 28px 40px;
	border-color:  transparent transparent $primary-color transparent;
}

.footer .anchorlink {
	top: -30px;
	width: 80px;
	height: 40px;
	margin-left: -40px;
	position: absolute;
	padding: 15px 0 0 0;
	font-size: 13px;
}

.footer a {
	color: $white;
	text-decoration: none; 
}

.footer .logo-b-secure {
	height: 40px;
	margin: 0 0 20px 0;
}

.footer .menu {
	display: inline-block;
}


/* ======================= /footer ======================== */

/* ======================= MediaQueries ======================== */

.breakpoint-indicator {
	position: fixed;
	width: 100%;
	text-align: right;
	padding: 0 15px;
	font-size: 12px;
	z-index: 100;
}

.breakpoint-indicator:before {
	content:'none';
}

@include breakpoint(small only) { 
// Only affects small screens

	.breakpoint-indicator:before {
		content:'small';
	}
}

@include breakpoint(small) { 
// Only affects small screens and larger
}

@include breakpoint(medium down) {
// Only affects medium screens and smaller
}

@include breakpoint(medium only) { 
// Only affects medium screens

	.breakpoint-indicator:before {
		content:'medium';
	}
}

@include breakpoint(medium) { 
// Only affects medium screens and larger
}

@include breakpoint(large down) {
// Only affects large screens and smaller
}

@include breakpoint(large only) { 
// Only affects large screens

	.breakpoint-indicator:before {
		content:'large';
	}
}

@include breakpoint(large) { 
// Only affects large screens and larger
}

@include breakpoint(xlarge down) {
// Only affects xlarge screens and smaller
}

@include breakpoint(xlarge only) { 
// Only affects xlarge screens

	.breakpoint-indicator:before {
		content:'xlarge';
	}
}

@include breakpoint(xlarge) { 
// Only affects xlarge screens and larger
}

@include breakpoint(xxlarge down) {
// Only affects xxlarge screens and smaller
}

@include breakpoint(xxlarge only) { 
// Only affects xxlarge screens

	.breakpoint-indicator:before {
		content:'xxlarge';
	}
}

@include breakpoint(xxlarge) { 
// Only affects xxlarge screens and larger
}

@include breakpoint(xxxlarge down) {
// Only affects xxxlarge screens and smaller
}

@include breakpoint(xxxlarge only) { 
// Only affects xxxlarge screens
}

@include breakpoint(xxxlarge) { 
// Only affects xxxlarge screens and larger

	.breakpoint-indicator:before {
		content:'xxxlarge';
	}
}



/* ======================= /MediaQueries ======================== */

