/* ======================= top-bar ======================== */

//Screen top-bar (fixed)

$topbar-height: 100px;

.top-bar {
    position: fixed;
    width: 100%;
    z-index: 99;
    height: $topbar-height;
	padding: 45px 0 0 0 ; //adjust vOffset:10 in HTML MARKUP dropdown-pane data-options
}

.top-bar .title-logo {
	position: absolute; 
	z-index: 100; 
	margin-left: 2rem;
	top: 0;
}

.top-bar .logo-b-secure {
	height: 50px;
	margin: 30px 0 0 0;
}

.top-space-screen {
	height: $topbar-height;
}


@include breakpoint(medium down) {
	.top-bar .logo-b-secure {
		height: 35px;
		margin: 38px 0 0 0;
	}
}


/* ======================= /top-bar ======================== */


.top-bar li a {
	color: $dark-gray;
	font-weight: 600;
	line-height: 1.3rem;
}

.top-bar .submenu {
	padding: 10px 0;
	background: $primary-color;
	text-align: right;
}

.top-bar .is-dropdown-submenu {
	border: 0px solid $primary-color;
}

.top-bar .submenu li {
	background: $primary-color;
	padding: 0 20px;
}

.top-bar .submenu li a {
	padding: 10px 0;
	color: $white;
	border-bottom: 1px solid $white;
}

.top-bar .submenu li:last-child a {
	//border-bottom: none;
}

@include breakpoint(medium down) {
	.top-bar li a {
		font-size: 15px;
	}
}
