/* ======================= title-bar ======================== */

$titlebar-height: 45px;

.title-bar {
    position: fixed;
    width: 100%;
    height: $titlebar-height;
	padding: 0 15px 0 15px;
    z-index: 99;
}

.title-bar .menu-icon {
	margin: 15px 0 15px 0;
}

.title-bar .title-logo {
	position: absolute; 
	z-index: 100; 
	top: 0; 
	margin: 0 0 0 25px;
}

.title-bar .logo-b-secure {
	height: 35px;
	margin: 5px 0 0 0;
}

.top-space-mobile {
	height: $titlebar-height;
}

/* ======================= title-bar ======================== */

/* ======================= off-canvas ======================== */

.off-canvas {
	height: 100%;
}

.off-canvas .nested {
	margin: 0;
}

.off-canvas li a {
	color: $white;
	background: $primary-color;
	border-bottom: 1px solid $white;
	line-height: 1.3rem;
	font-weight: 600;
}

.off-canvas .nested li a {
	color: $primary-color;
	background: $white;
	border-bottom: 1px solid $medium-gray;
}

/* ======================= off-canvas ======================== */
